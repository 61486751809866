html {
	background: #2b2b40;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Rubik', sans-serif;
}

a {
	color: #00a4ca;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	max-width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 300;
	font-size: 3.8em;
	margin: 0;
}

.cd_cover {
	    align-self: flex-start;
}

h2 {
	font-weight: 300;
	font-size: 3em;
	margin: 0 0 30px 0;
	line-height: 1.2em;
}

.concerts, .contact {
	text-align: center;

}

h3 {
	font-size: 2.2em;
	margin: 7vh 0 0;
}

h4 {
	font-size: 1.8em;
	margin: 0px 0 0px 0;
	text-align: center;
}

h2 a {
	color: #000;

	&:hover {
		text-decoration: none;
	}
}

p, address, ul {
	font-size: 1.38em;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.6em;
	font-family: 'Lato', sans-serif;
}

.flex-articles {
	align-items: baseline!important;
}
